import * as React from "react"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const ServicesIndex = () => {
	const TheTitle = "Landscaping Services"
	const TheDescription = "Escape the Yardwork with Garden Escape's landscaping services. We service the Greater Vancouver, Tri-cities, Maple Ridge, Pitt Meadows and Langley"
	const TheCanonical = "https://gardenescape.ca/services/"
	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />

			<div className="py-8">
				<div aria-labelledby="services-title" className="mainWrapper my-8">
					<div className="my-0 mx-auto flex flex-col">
						<h1 id="services-title" className="pb-0 mb-2">
							OUR FULL LIST OF SERVICES
						</h1>
						<p className=" mt-0 pt-0 text-center">
							We offer a wide range of services to suit your specific needs
						</p>

						<div className="homeServicesList">
							<ul>
								<li>
									<Link to="/services/lawn-mowing/" title="lawn mowing services">✓ Lawn Mowing</Link>
								</li>
								<li>
									<Link to="/services/lawn-fertilizing/" title="lawn fertilizing services">✓ Lawn
										Fertilizing</Link>
								</li>
								<li>
									<Link to="/services/yard-cleanup/" title="yard cleanup services">✓ Seasonal Yard
										Cleanup</Link>
								</li>
								<li>
									<Link to="/services/shrub-pruning/" title="hedge and shrub pruning services">✓ Hedge &
										Shrub Pruning</Link>
								</li>
							</ul>
							<ul>
								<li>
									<Link to="/services/lawn-aeration/" title="Lawn Aeration services">✓ Lawn
										Aeration</Link>
								</li>
								<li>
									<Link to="/services/power-washing/" title="Power Raking services">✓ Power Raking</Link>
								</li>
								<li>
									<Link to="/services/power-washing/" title="Power Washing services">✓ Power
										Washing</Link>
								</li>
								<li>
									<Link to="/services/junk-removal/" title="Junk Removal services">✓ Junk Removal</Link>
								</li>
								<li>
									<Link to="/services/hardscaping/" title="hardscape and hardscaping services">✓
										Hardscaping</Link>
								</li>
							</ul>
						</div>
						<div className="mx-auto mt-8">
							<Link to="/contact/" title="call to get 25% off your first mow">
								<button>
									I want 25% off my first mow!
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="homeAltGridWrapper m-0 p-0">
				<div>
					<div className="homeAltGrid">
						<StaticImage
							className="homeAltGrid_img"
							src="../../images/home/grass.png"
							alt="fresh cut grass"
							layout="fullWidth" />
						<div className="homeAltGrid_boxA">
							<p className="homeAltGrid_boxA_text">LAWN MAINTENANCE</p>
						</div>
					</div>
				</div>
				<div>
					<div className="homeAltGrid">
						<StaticImage
							className="homeAltGrid_img"
							src="../../images/home/leaves.png"
							alt="raked leaves"
							layout="fullWidth" />
						<div className="homeAltGrid_boxA">
							<p className="homeAltGrid_boxA_text">SEASONAL CLEANUP</p>
						</div>
					</div>
				</div>
				<div>
					<div className="homeAltGrid">
						<StaticImage
							className="homeAltGrid_img"
							src="../../images/home/bush.png"
							alt="trimmed and pruned hedges"
							layout="fullWidth" />
						<div className="homeAltGrid_boxA">
							<p className="homeAltGrid_boxA_text">TRIMMING PRUNING</p>
						</div>
					</div>
				</div>
			</div>

		</Layout>
	)
}

export default ServicesIndex